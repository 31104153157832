<template>
  <div class="flex h-full">
    <div class="bg-primary-body w-2/6 h-full hidden lg:flex items-center justify-center">
      <img src="@/assets/img/aside-img-login.png" alt="Bienvenido a SingularRank" >
    </div>
    <div class="flex flex-col w-full lg:w-4/6 min-h-full bg-white">
      <div class="flex flex-grow items-center justify-center">
        <div class="w-full px-4 max-w-lg mx-auto">
          <router-link :to="{ path: '/' }">
            <base-logo filename="singular-rank-logo-horizontal.svg" alt="Bienvenido a SingularRank" custom="mx-auto pb-4" />
          </router-link>
          <form @submit.prevent="recoveryPassword">
            <div class="w-full mb-2">
              <label for="password" class="text-xs uppercase font-semibold text-secondary-500">
                {{ $t('app.newPassword') }}
              </label>
              <base-input prepend="lock" custom="w-full" v-model="form.password" type="password" :placeholder="$t('app.password')" name="password"/>
            </div>
            <div class="w-full mb-4">
              <label for="repassword" class="text-xs uppercase font-semibold text-secondary-500">
                {{ $t('app.rePassword') }}
              </label>
              <base-input prepend="lock" custom="w-full" v-model="form.confirmPassword" type="password" :placeholder="$t('app.password')" name="repassword"/>
            </div>
            <base-button :loading="loading" class="w-full" type="submit" :text="$t('app.submit')" />
            <p v-if="error" class="text-sm mt-2 text-center text-danger font-medium">{{ $t('feedback.error.wrongLogin') }}</p>
          </form>
        </div>
      </div>
      <public-footer />
    </div>
  </div>
</template>

<script>
import PublicFooter from '@/components/PublicFooter.vue'
import { recoveryPassword } from '@/services/auth/recoveryPassword'

export default {
  name: 'recovery-password',
  components: {
    PublicFooter
  },
  data: function () {
    return {
      error: false,
      form: {
        password: '',
        confirmPassword: ''
      },
      loading: false
    }
  },
  methods: {
    recoveryPassword: async function () {
      if (this.form.password && this.form.confirmPassword) {
        if (this.form.password === this.form.confirmPassword) {
          try {
            this.loading = true
            const token = this.$route.query.token
            await recoveryPassword(token, this.form.password)
            this.$router.push({ name: 'login', params: { feedback: 'updatedPassword' } })
          } catch (error) {
            this.doFeedback()
          } finally {
            this.loading = false
          }
        } else {
          this.doFeedback(this.$t('feedback.error.differentPassword'))
        }
      } else {
        this.doFeedback(this.$t('feedback.error.all'))
      }
    }
  }
}
</script>
