import axiosInstance from '@/services/axiosInstance'

async function recoveryPassword (token, password) {
  const data = {
    token,
    password
  }

  const req = {
    url: process.env.VUE_APP_URL_API_BASE + '/recovery-password',
    method: 'POST',
    data
  }

  const res = await axiosInstance(req)
  return res.data
}

export { recoveryPassword }
