<template>
  <div class="flex-shrink-0 flex items-center justify-between px-4 h-16 text-sm text-secondary-500">
    <div class="flex items-center space-x-2">
      <p>{{ $t('footer.needHelp') }}</p> <span><base-icon name="mobile" size="xs" /><a href="tel:+34984187455"> +34 984 187 455</a></span> <span><base-icon name="email" size="xs" /><a href="mailto:soporte@singularrank.com"> {{ $t('footer.supportEmail') }}</a></span>
    </div>
    <div>
      <p>
        <span>&copy;{{ currYear }} SingularRank</span> &middot; <router-link :to="{ name: 'legal' }">{{ $t('footer.legalText') }}</router-link> &middot; <router-link :to="{ name: 'privacy' }">{{ $t('footer.privacity') }}</router-link> &middot; <router-link :to="{ name: 'cookies' }">{{ $t('footer.cookies') }}</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import BaseIcon from './base/BaseIcon.vue'
export default {
  components: { BaseIcon },
  name: 'public-footer',
  data: function () {
    return {
      currYear: '...'
    }
  },
  created: function () {
    this.setDate()
  },
  methods: {
    setDate: function () {
      const date = new Date()
      this.currYear = date.getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
