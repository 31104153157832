<template>
  <div class="flex h-full">
    <div class="bg-primary-body w-2/6 h-full hidden lg:flex items-center justify-center">
      <img src="@/assets/img/aside-img-login.png" alt="Bienvenido a SingularRank" >
    </div>
    <div class="flex flex-col w-full lg:w-4/6 min-h-full bg-white">
    <div class="flex-shrink-0 flex items-center justify-end p-4">
        <p class="text-sm pr-2">{{ $t('app.areYouRegistered') }}</p>
        <router-link :to="{name: 'login'}">
          <base-button mode="soft" type="submit" :text="$t('app.login')" />
        </router-link>
      </div>
      <div class="flex flex-grow items-center justify-center">
        <div class="w-full px-4 max-w-lg mx-auto">
          <router-link :to="{ path: '/' }">
            <base-logo filename="singular-rank-logo-horizontal.svg" alt="Bienvenido a SingularRank" custom="mx-auto pb-4" />
          </router-link>
          <form @submit.prevent="forgotPassword">
            <div class="w-full mb-2">
              <p>{{ $t('app.textForgotPassword') }}</p>
            </div>
            <div class="w-full mb-4">
              <label for="repassword" class="text-xs uppercase font-semibold text-secondary-500">
                {{ $t('app.email') }}
              </label>
              <base-input prepend="email" custom="w-full" v-model="form.email" type="text" :placeholder="$t('app.email')" name="email"/>
            </div>
            <base-button :loading="loading" class="w-full" type="submit" :text="$t('app.submit')" />
            <p v-if="error" class="text-sm mt-2 text-center text-danger font-medium">{{ $t('feedback.error.wrongLogin') }}</p>
          </form>
        </div>
      </div>
      <public-footer />
    </div>
  </div>
</template>

<script>
import PublicFooter from '@/components/PublicFooter.vue'
import { forgotPassword } from '@/services/auth/forgotPassword'

export default {
  name: 'forgot-password',
  components: {
    PublicFooter
  },
  data: function () {
    return {
      error: false,
      form: {
        email: ''
      },
      loading: false
    }
  },
  methods: {
    forgotPassword: async function () {
      if (this.form.email) {
        try {
          this.loading = true
          await forgotPassword(this.form.email)
          this.$router.push({ name: 'login', params: { feedback: 'sendedForgotEmail' } })
        } catch (error) {
          if (error?.response?.status === 400 && parseInt(error?.response?.data?.codigo_error) === 4007) {
            this.doFeedback(this.$t('feedback.error.noEmail'))
          } else {
            this.doFeedback()
          }
        } finally {
          this.loading = false
        }
      } else {
        this.doFeedback(this.$t('feedback.error.email'))
      }
    }
  }
}
</script>
