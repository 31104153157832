import axiosInstance from '@/services/axiosInstance'

async function forgotPassword (email) {
  const data = {
    email
  }

  const req = {
    url: process.env.VUE_APP_URL_API_BASE + '/forgot-password',
    method: 'POST',
    data
  }

  const res = await axiosInstance(req)
  return res.data
}

export { forgotPassword }
